/*
 * @Description: 维护物料和组合混入
 * @Author: ChenXueLin
 * @Date: 2021-11-17 09:48:14
 * @LastEditTime: 2022-10-19 13:51:23
 * @LastEditors: ChenXueLin
 */
export default {
  data() {
    return {
      materialCombinationVisible: false
    };
  },
  mixins: [],
  components: {},
  computed: {},
  watch: {},
  created() {},
  activated() {},
  methods: {
    handleCombination() {
      this.materialCombinationVisible = true;
    }
  }
};
